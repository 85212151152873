import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { getBallotService, getOrganizationService } from '@asd-stan/ballot/infrastructure/getters';
import { useRequest } from '@asd-stan/helpers/use-request';
import { ReactComponent as LogoIcon } from '@asd-stan/ui-kit/assets/asd-stan.svg';
import { ReactComponent as EditIcon } from '@asd-stan/ui-kit/assets/icons/edit.svg';
import { BreadCrumbs } from '@components/bread-crumbs/bread-crumbs';
import { IconButton } from '@components/button/icon-button';
import { Loading } from '@components/loading/loading';
import { PageTitle } from '@components/page-title/page-title';
import { Tabs } from '@components/tabs/tabs';
import { Flex } from '@components/utility/flex';

import {
	StyledBallotStatus,
	StyledLoaderContainer,
	StyledSingleBallot,
} from './single-ballot.styled';
import { General } from './tabs/general/general';
import { Result } from './tabs/result/result';

import { StyledButtonGroup } from '@components/utility/button-group.styled';
import { ContentContainer } from '@components/utility/content-container.styled';

enum TabKey {
	General = 'general',
	Result = 'result',
}

export const SingleBallot = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ballotService = getBallotService();
	const organizationService = getOrganizationService();
	const params = useParams();
	const id = Number(params.id);
	const {
		data: ballot,
		isLoading: ballotLoading,
		invalidate: invalidateBallot,
	} = useRequest(useCallback(() => ballotService.getSingleBallot(id), [id]));

	const tabList = [
		{ tabKey: TabKey.General, title: t('ballot.singleBallot.tabs.general') },
		{
			tabKey: TabKey.Result,
			title: t('ballot.singleBallot.tabs.result'),
		},
	];

	useEffect(() => {
		organizationService.getOrganizations();
	}, []);

	const getTabComponent = (key: TabKey) => {
		if (!ballot) {
			return null;
		}
		switch (key) {
			case TabKey.General:
				return (
					<General
						ballotId={id}
						ballot={ballot}
						vote={ballot.myVote}
						onVoteSubmit={invalidateBallot}
					/>
				);

			case TabKey.Result:
				return <Result ballotId={id} ballot={ballot} />;
		}
	};

	if (ballotLoading) {
		return (
			<ContentContainer>
				<StyledLoaderContainer>
					<Flex $align="center" $justify="center" $direction="column">
						<LogoIcon />
						<Loading horizontal />
					</Flex>
				</StyledLoaderContainer>
			</ContentContainer>
		);
	}

	if (!ballot) {
		return null;
	}

	return (
		<StyledSingleBallot>
			<ContentContainer>
				<BreadCrumbs />
				<Flex $justify="space-between" $align="flex-start">
					<PageTitle title={ballot.title} className="title" />
					<StyledButtonGroup>
						{ballotService.checkIfCurrentUserIsAbleToUpdateBallot(ballot.createdById) && (
							<IconButton
								icon={<EditIcon />}
								onClick={() => navigate(`/ballots/edit/${params.id}`)}
							/>
						)}
					</StyledButtonGroup>
				</Flex>
				<StyledBallotStatus>
					{t('ballot.singleBallot.status')} <span>{t(`ballot.ballotStatus.${ballot.status}`)}</span>
				</StyledBallotStatus>
				<Tabs tabLists={tabList} basePath={`/ballots/${id}`}>
					<Routes>
						{tabList.map(({ tabKey }) => (
							<Route key={tabKey} path={tabKey} element={getTabComponent(tabKey)} />
						))}
					</Routes>
				</Tabs>
			</ContentContainer>
		</StyledSingleBallot>
	);
});
