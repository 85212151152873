import { observer } from 'mobx-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
	BallotStatus,
	Decision,
	MyVote,
	Question,
	SingleBallot,
	ValidatedVotingForm,
	VotingDecision,
	VotingForm as VotingFormType,
} from '@asd-stan/ballot/domain/ballot.entity';
import { getBallotService, getOrganizationService } from '@asd-stan/ballot/infrastructure/getters';
import { FileUploadController } from '@asd-stan/file/domain/file-upload-controller';
import { ToasterStatus } from '@asd-stan/shell/domain/toaster.service';
import { getToasterService } from '@asd-stan/shell/infrastructure/getters';
import { Button } from '@components/button/button';
import { FileDropzone, checkIfFilesBeingUploaded } from '@components/file-dropzone/file-dropzone';
import { FormInputParagraph } from '@components/form-input-paragraph/form-input-paragraph';
import { FormSelect } from '@components/form-select/form-select';
import { Flex } from '@components/utility/flex';
import { Field, Form, Formik, FormikErrors, useFormikContext } from 'formik';

import { ReactComponent as WarningIcon } from '../../../../assets/warning.svg';
import { StyledNoPermissionToVote, StyledVoteSection } from '../../../../single-ballot.styled';

import { validationSchema } from './validationSchema';

const buttonsOptions = [
	{ value: VotingDecision.Approve, labelKey: 'yes' },
	{ value: VotingDecision.Disapprove, labelKey: 'no' },
	{ value: VotingDecision.Abstain, labelKey: 'abstention' },
];

const maxSizeBytes = 20 * 1024 * 1024;

const fileTypes = {
	'image/jpeg': ['.jpeg'],
	'image/jpg': ['.jpg'],
	'image/png': ['.png'],
	'image/svg': ['.svg'],
	'application/xml': ['.xml'],
	'application/vnd.ms-excel': ['.xls'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
	'text/csv': ['.csv'],
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
	'image/tiff': ['.tiff'],
	'image/vnd.dwg': ['.dwg'],
	'image/bmp': ['.bmp'],
	'application/zip': ['.zip'],
};

const VotingForm = observer(
	({
		questions,
		ableToVote,
		isNewVote,
	}: {
		questions: Question[];
		ableToVote: boolean;
		isNewVote: boolean;
	}) => {
		const { t } = useTranslation();
		const toasterService = getToasterService();
		const organizationService = getOrganizationService();
		const { setFieldValue, values, errors, touched, isSubmitting, validateForm, handleSubmit } =
			useFormikContext<VotingFormType>();
		const fileUploadContrallerRef = useRef<null | FileUploadController>(null);

		if (fileUploadContrallerRef.current === null) {
			fileUploadContrallerRef.current = new FileUploadController();
		}

		const organizationOptions = organizationService.organizations.map(organization => {
			return { label: organization.name, value: organization.id };
		});

		const submittingDisabled =
			isSubmitting || !ableToVote || checkIfFilesBeingUploaded(values.attachments);

		return (
			<StyledVoteSection as={Form}>
				<h4>{t('ballot.singleBallot.voting')}</h4>
				<div className="block">
					<Field
						component={FormSelect}
						name="organization"
						title={t('ballot.singleBallot.voteAs')}
						options={organizationOptions}
						isLoading={organizationOptions.length === 0}
						fullWidth
						mandatory
						showError
						disabled={!ableToVote}
					/>
				</div>
				{questions.map(({ comment, id, title }, index) => {
					const decisionError =
						touched.decisions?.[index]?.decision &&
						errors.decisions?.[index] &&
						(errors.decisions[index] as FormikErrors<Decision>).decision;

					return (
						<div key={id} className="block">
							<h4>
								{t('ballot.singleBallot.vote')} {index + 1}
							</h4>
							<p className="block-title">
								{title}
								<span>*</span>
							</p>
							<div className="buttons">
								<div className="buttons-wrapper">
									{buttonsOptions.map(({ labelKey, value }) => {
										const classNames = [];
										if (!ableToVote) {
											classNames.push('disabled');
										}
										if (values.decisions?.[index]?.decision === value) {
											classNames.push('active');
										}

										return (
											<button
												key={value}
												type="button"
												className={classNames.join(' ')}
												onClick={() => setFieldValue(`decisions[${index}].decision`, value)}
												disabled={!ableToVote}>
												{t(`ballot.singleBallot.${labelKey}`)}
											</button>
										);
									})}
								</div>
								{!!decisionError && <div className="buttons-error">{decisionError}</div>}
							</div>
							<Field
								component={FormInputParagraph}
								name={`decisions[${index}].comment`}
								title={comment || t('ballot.singleBallot.descriptionComment')}
								fullWidth
								showError
								maxLength={5000}
								disabled={!ableToVote}
							/>
						</div>
					);
				})}
				<div className="block">
					<Field
						name="attachments"
						component={FileDropzone}
						fileTypes={fileTypes}
						maxSize={maxSizeBytes}
						maxFiles={0}
						controller={fileUploadContrallerRef.current}
						isFilePrivate
						disabled={!ableToVote}
					/>
				</div>
				<Flex $justify="flex-end">
					<Button
						onClick={async () => {
							validateForm().then(errors => {
								if (errors && !(Object.keys(errors).length === 0)) {
									toasterService.showToast(
										ToasterStatus.error,
										t('ballot.singleBallot.errors.validationErrorToast')
									);
								}
							});
							handleSubmit();
						}}
						title={t(`ballot.singleBallot.${isNewVote ? 'saveVote' : 'updateVote'}`)}
						disabled={submittingDisabled}
					/>
				</Flex>
			</StyledVoteSection>
		);
	}
);

export const Voting = ({
	ballotId,
	ballot,
	vote,
	onVoteSubmit,
}: {
	ballotId: number;
	ballot: SingleBallot;
	vote: MyVote | null;
	onVoteSubmit(): void;
}) => {
	const { t } = useTranslation();
	const ballotService = getBallotService();
	const toasterService = getToasterService();

	const handleSubmit = async (voteValues: VotingFormType) => {
		try {
			if (vote) {
				await ballotService.updateVote(vote.id, ballotId, voteValues as ValidatedVotingForm);
			} else {
				await ballotService.createVote(ballotId, voteValues as ValidatedVotingForm);
			}
			toasterService.showToast(ToasterStatus.success, t('ballot.singleBallot.voted'));
			onVoteSubmit();
		} catch (err) {
			console.error(err);
		}
	};

	const isBallotOpen = ballot.status === BallotStatus.Active;
	const isUserAbleToVote = ballotService.checkIfCurrentUserIsAbleToVote(ballot.selectedVotersIds);
	const ableToVote = isBallotOpen && isUserAbleToVote;

	const initialValues: VotingFormType = vote?.form ?? {
		organization: null,
		decisions: ballot.questions.map(q => ({
			id: null,
			questionId: q.id,
			comment: '',
			decision: null,
		})),
		attachments: [],
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}>
			<>
				{!ableToVote && (
					<StyledNoPermissionToVote>
						<WarningIcon />
						{t('ballot.singleBallot.noPermission')}
					</StyledNoPermissionToVote>
				)}
				<VotingForm ableToVote={ableToVote} questions={ballot.questions} isNewVote={!vote} />
			</>
		</Formik>
	);
};
