import { injector } from '@asd-stan/injector/injector';

import { BallotRepo } from '../api/ballot.repo';
import { BallotService } from '../domain/ballot.service';

import { BALLOT_REPO, BALLOT_SERVICE, ORGANIZATION_REPO, ORGANIZATION_SERVICE } from './constants';
import { OrganizationService } from "@asd-stan/ballot/domain/organization.service";
import { OrganizationRepo } from "@asd-stan/ballot/api/organization/organization.repo";

export const getBallotRepo = () => {
	return injector.get<BallotRepo>(BALLOT_REPO);
};

export const getBallotService = () => {
	return injector.get<BallotService>(BALLOT_SERVICE);
};

export const getOrganizationService = () => {
	return injector.get<OrganizationService>(ORGANIZATION_SERVICE);
};

export const getOrganizationRepo = () => {
	return injector.get<OrganizationRepo>(ORGANIZATION_REPO);
};