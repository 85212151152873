import { gql } from '@apollo/client';
import { stanWorksClient } from '@asd-stan/config/api';
import { Organization } from "@asd-stan/ballot/domain/organization.entity";
import { OrganizationListDTO } from "@asd-stan/ballot/api/organization/organization.dto";
import { makeOrganizationFromOrganizationDTO } from "@asd-stan/ballot/api/organization/organization.factory";

const ALL_ORGANIZATIONS = gql`
	query {
		allBallotOrganizations {
			id
			name
		}
	}
`;

export class OrganizationRepo {
	async getOrganizations(): Promise<Organization[]> {
		const { data } = await stanWorksClient.query<OrganizationListDTO>({
			query: ALL_ORGANIZATIONS,
		});

		return data.allBallotOrganizations.map(dto => makeOrganizationFromOrganizationDTO(dto));
	}
}
