import { makeAutoObservable, runInAction } from 'mobx';
import { Organization } from "@asd-stan/ballot/domain/organization.entity";
import { getOrganizationRepo } from "@asd-stan/ballot/infrastructure/getters";

export class OrganizationService {
	private _organizations: Organization[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	private get _organizationRepo() {
		return getOrganizationRepo();
	}

	public async getOrganizations() {
		const organizations = await this._organizationRepo.getOrganizations();

		runInAction(() => {
			this._organizations = organizations;
		});
	}

	get organizations() {
		return this._organizations;
	}
}
