import '@asd-stan/domain/infrastructure/constants';
import { injector } from '@asd-stan/injector/injector';

import { BallotRepo } from '../api/ballot.repo';
import { BallotService } from '../domain/ballot.service';

import { BALLOT_REPO, BALLOT_SERVICE, ORGANIZATION_REPO, ORGANIZATION_SERVICE } from './constants';
import { OrganizationService } from "@asd-stan/ballot/domain/organization.service";
import { OrganizationRepo } from "@asd-stan/ballot/api/organization/organization.repo";

injector.set(BALLOT_REPO, new BallotRepo());
injector.set(BALLOT_SERVICE, new BallotService());
injector.set(ORGANIZATION_REPO, new OrganizationRepo());
injector.set(ORGANIZATION_SERVICE, new OrganizationService());
