import { CatalogItemDTO } from '@asd-stan/standard/api/catalog.dto';
import { CatalogItem } from '@asd-stan/standard/domain/catalog-item.entity';
import { FormikValues } from 'formik';
import moment from 'moment';

export const makeCatalogFromCatalogDTO = (dto: CatalogItemDTO): CatalogItem => {
	return new CatalogItem({
		id: dto.id,
		standardId: dto.standardId,
		name: dto.name,
		title: dto.title,
		publicationDate: dto.publicationDate,
		availableForPurchase: dto.availableForPurchase,
		published: dto.published,
		localizedTitle: dto.localizedTitle,
		price: dto.price,
		description: dto.description,
		coverPage: dto.coverPage,
		show: dto.show,
		isTop: dto.isTop,
		form: dto.form,
		registrationNumber: dto.registrationNumber,
		types: dto.types,
		edition: dto.edition ?? '',
		languages: dto.languages,
		domains: dto.domains,
		scope: dto.scope ?? '',
		internalReferences: dto.internalReferences,
		externalReferences: dto.externalReferences ?? '',
		tags: dto.tags,
		status: dto.status,
		publisher: dto.publisher,
		file: dto.file
			? {
				fileId: dto.file.id,
				path: dto.file.path,
				size: dto.file.size,
				title: dto.file.name,
			}
			: null
	});
};

export const makePartialCatalogItemDTO = (values: FormikValues) => {
	return {
		id: values.id,
		name: values.name,
		title: values.title,
		publicationDate: moment(values.publicationDate).format('YYYY-MM'),
		availableForPurchase: values.availableForPurchase.value === 'true',
		published: values.published,
		localizedTitle: values.localizedTitle,
		price: +values.price,
		description: values.description,
		coverPage: values.coverPage[0]?.fileId,
		isTop: values.isTop,
		form: values.form?.value ?? null,
		registrationNumber: values.registrationNumber,
		typeIds: values.types.map(({ value }: { value: number }) => value),
		edition: values.edition,
		languages: values.languages.map(({ value }: { value: string }) => value),
		domainCodes: values.domains.map(({ value }: { value: string }) => value),
		scope: values.scope,
		internalReferences: values.internalReferences.map(({ value }: { value: number }) => value),
		externalReferences: values.externalReferences,
		tags: values.tags.map(({ value }: { value: string }) => value),
		status: values.status?.value,
		fileId: values.fileId?.[0]?.fileId ?? null,
	};
};
