import { makeAutoObservable } from 'mobx';

export interface OrganizationArgs {
	id: number;
	name: string;
}

export class Organization {
	public id: number;
	public name: string;

	constructor(args: OrganizationArgs) {
		makeAutoObservable(this);

		this.id = args.id;
		this.name = args.name;
	}
}
